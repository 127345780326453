<template>
    <router-view/>
    <preview-course v-if="openClosePreview" :previewId="previewId" :previewType="previewType"></preview-course>
    <div class="banner-update-app" v-if="isRefresh && this.$route.name != 'notifications'">  
        <div class="text-center">
          <h5><i class="las la-bullhorn text-primary"></i> Nueva Actualización Disponible</h5>
          <p class="small m-0 mb-2">Necesitamos que vuelvas a recargar el sitio para sumar las nuevas actualizaciones.</p>
          <button class="btn btn-outline-primary mx-auto" @click="updateApp()">Recargar</button>
        </div>
    </div>
    <div class="d-flex justify-content-center align-items-center h-100 w-100 screen-waiting" v-if="this.showWait == true">
        <div>
            <img src="@/assets/imgs/spinner-white.webp" class="img-fluid w-25">
            <h3>{{waitingMsg}}</h3>    
        </div>
    </div>
    <pdf-reader-iframe v-if="pdfShow" :url="pdfUrl"></pdf-reader-iframe>
</template>
<script>   
import previewCourse from '@/components/edit-service/preview-course.vue'
import pdfReaderIframe from '@/components/my-account/pdf-reader-iframe.vue'
export default{
  components: {
      pdfReaderIframe,
      previewCourse
  },
  data(){
        return{
          openClose: false, 
          openTutorial: '',
          titleTutorial: '',
          registration:null,
          isRefresh: false,
          refreshing: false,
          pdfUrl: '',
          pdfShow: false,
          showWait: false,
          waitingMsg: '',
          previewId: '',
          previewType: '',
          openClosePreview: false,
        }
  },
  mounted: function () { 
  
  },
  created() {
    document.addEventListener(
        'serviceWorkerUpdateEvent', this.appUpdateUI, { once: true }
    );
    navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
          if (this.refreshing) return;
          if(this.$route.name != 'notifications'){
            this.refreshing = true;
            window.location.reload();
          }
        }
    );
  },

  methods: {
    closePdf(){
      try{
        document.querySelector('body').style.height = '';
        document.querySelector('body').style.overflow = '';
      }catch(e){

      }
      this.pdfShow = false;
      this.pdfUrl = '';
    },
    openPdf(val){ 
      if(window.innerWidth > 572){
        try{
          document.querySelector('body').style.height = '100vh';
          document.querySelector('body').style.overflow = 'hidden';
        }catch(e){

        }    
        this.pdfUrl = val;
        this.pdfShow = true;
      }else{
        var anchor = document.createElement('a');
        anchor.href = val;
        anchor.target="_blank";
        anchor.click();
      }
    },
    showWaiting(val){ 
      this.waitingMsg = val;
      this.showWait = true;
    },
    hideWaiting(){
      this.showWait = false;
      this.waitingMsg = '';
    },
    openPreview(val1, val2){
      this.previewId = val1;
      this.previewType = val2;
      this.openClosePreview = false;
      var d =  this;
      setTimeout(function(){ d.openClosePreview = true; }, 200);
    },
    closePreview(){
      this.previewId = '';
      this.previewType = '';
      this.openClosePreview = false;
    },
    openHelp(val){
      this.titleTutorial = '';
      this.openTutorial = '';
      if(val == '' && this.openClose == true){
        this.openClose = false;
      }else{
        this.openClose = true;
      }
    },
    openTutorialHelp(val1, val2){
      this.openClose = false;
      this.titleTutorial = val2;
      this.openTutorial = val1;
      var d =  this;
      setTimeout(function(){ d.openClose = true; }, 200);
   
    },
    appUpdateUI:function (e){
      this.registration = e.detail;
      this.isRefresh = true;
    },
    updateApp(){
     this.isRefresh = false;
     if (this.registration || this.registration.waiting) {
       this.registration.waiting.postMessage({type:'SKIP_WAITING'});
     }
   },
  },
  
}
</script>

<style lang="css" src="./assets/styles/custom.css" />
<style lang="css" src="./assets/styles/animate.css" />
<style lang="css" src="@/assets/styles/slim.min.css" />
<style>
b, strong {
    font-weight: bolder !important;
}
</style>
<style scoped>
.screen-waiting{
    position: fixed;
    z-index: 9000000;
    text-align: center;
    background: #004193;
    color: #fff;
    top:0;
    left: 0;
}
.banner-update-app{
  position: fixed;
    z-index: 2000001;
    bottom: 0;
    width: 100%;
    background: #000;
    color: #fff;
    height: auto;
    padding: 10px;
}

</style>

        
